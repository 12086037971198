<template>
  <div id="screenView">
    <header class="top">
      <h1>碰了么大数据可视化平台</h1>
      <div class="showTime"></div>
    </header>
    <section class="content">
      <div class="column">
        <MyVideo></MyVideo>
        <my-swipper title="充电实时订单" :tableDate="baggedDate">
        </my-swipper>

      </div>

      <div class="column columu_map">
        <my-map :users="users" :sumMachineData="sumMachineData" :sumOrderData="sumOrderData"
          :sumMoneyData="sumMoneyData"></my-map>
      </div>

      <div class="column">
        <my-swipper title="wifi实时链接" :tableDate="hoteDate">
        </my-swipper>


      </div>
    </section>
    <!-- 中间图形-->
    <div class="column2">

      <my-charts :option="option" type="line"></my-charts>
    </div>
  </div>
</template>

<script>
import MyMap from "./components/MyMap.vue";
import MySwipper from "./components/MySwipper.vue";
import MyCharts from "./components/MyCharts.vue";
import MyVideo from './components/MyVideo.vue'
import {

  PleGetList
} from "./apis/Menu.js";
export default {
  components: {
    MyMap,
    MySwipper,
    MyCharts,
    MyVideo
  },
  data() {
    return {
      option7: {}, // 地图数据
      option: {},//条形图数据
      orderList: [],
      orderListX: [],
      ChargeListY: [],
      WifiListY: [],
      userUmTotal: [],
      users: [
        { id: 0, title: "销售总额", count: "191343379.68" },
        { id: 1, title: "销售订单数", count: "131151258" },
        { id: 2, title: "设备总数", count: "658584" },
      ], // 职场大数据统计
      // 共享毛巾参数
      list: {
        end_time: "",
        lenth: 10,
        start_time: "",
      },
      tableDate: [],
      // 有袋环保袋实时订单参数
      baggedList: {
        end_time: "",
        goods_name: "",
        hotel_name: "",
        lenth: 10,
        start_time: "",
      },
      baggedDate: [],
      //共享隔空无线充实时订单
      airDdte: [],
      // 汉代环保袋实时订单
      hanbagDate: [],
      //酒店售货机实时订单
      hotelList: {
        lenth: 10,
        limit: 10,
      },
      hoteDate: [],
      //共享充电线实施订单
      shardDate: [],
      // 共享毛巾实时订单
      ShareTowelDate: [],
      // 销售数据总数量
      sumMachineData: 0,
      // 小手订单
      sumOrderData: 0,
      //销售总价
      sumMoneyData: 0,
      refreshData: null,
      resizeListener: null,
    };
  },
  mounted() {
    this.resizeListener = this.resizeECharts;
    window.addEventListener("resize", this.resizeListener);
    document.querySelector(".showTime").innerHTML = this.getYMD();
    this.refreshData = setInterval(() => {
      this.PleGetListWay()

    }, 1000 * 60 * 30);

    this.$nextTick(() => {

      this.PleGetListWay()
    });
  },
  //生命周期若已设置定时器，需清空定时器beforeDestroy()
  beforeDestroy() {
    clearInterval(this.refreshData);
    this.refreshData = null;
    // 清除窗口大小变化的监听器
    if (this.resizeListener) {
      window.removeEventListener("resize", this.resizeListener);
    }
    this.resizeListener = null;
  },
  methods: {
    resizeECharts() {
      const containerWidth = document.getElementById('chart-container').offsetWidth;

      // 根据容器宽度计算新的字体大小
      const fontSize = Math.max(12, Math.min(16, containerWidth / 20));
      console.log('获取元素宽度', containerWidth)
      // 更新ECharts选项中的文本样式

      const colors = ['#5470C6', '#91CC75'];
      if (containerWidth > 768) {

        this.option = {
          title: {
            text: '近30天连接数', // 标题文本
            textStyle: {
              color: '#fff', // 标题文字颜色
              fontSize: 25 // 标题文字大小
            },
            padding: 10 // 标题内边距
          },
          color: colors,
          backgroundColor: 'rgba(0, 0, 0, 0.6)', // 透明度为0.1的黑色背景
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '150',
            left: '60', // Adjust as needed
            top: '28%',  // Adjust as needed
            bottom: '10%' // Adjust as needed
          },
          toolbox: {

          },
          legend: {
            textStyle: {
              color: colors // 图例文字颜色
            },
            data: ['wifi链接数', '充电次数']
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              // prettier-ignore
              data: this.orderListX
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: 'wifi链接数',
              position: 'left',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
            {
              type: 'value',
              name: '充电次数',
              position: 'right',
              alignTicks: true,
              offset: 80,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1]
                }
              },
              axisLabel: {
                formatter: '{value}'
              }
            },

          ],
          series: [
            {
              name: 'wifi链接数',
              type: 'bar',
              data: this.WifiListY
            },
            {
              name: '充电次数',
              type: 'bar',
              yAxisIndex: 1,
              data: this.ChargeListY
            }
          ]
        };
      } else {
        this.option = {
          title: {
            text: '近30天连接数',
            textStyle: {
              color: '#fff',
              fontSize: 25
            },
            padding: 10
          },
          color: colors,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            right: '15%',
            left: '15%', // 调整左侧边距
            top: '8%',  // 调整顶部边距
            bottom: '5%' // 调整底部边距
          },
          toolbox: {},
          legend: {
            textStyle: {
              color: colors
            },
            x: 'right', // 指定图例在右侧
            data: ['wifi链接数', '充电次数']
          },
          xAxis: [
            {
              type: 'value', // 使用value类型的x轴
              name: 'wifi链接数',
              position: 'bottom', // 将x轴放在底部
              nameRotate: -90, // 设置名称的旋转角度为90度，使其垂直显示
              nameGap: 25, // 设置名称与坐标轴之间的距离
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              alignTicks: true,
              axisLabel: {
                formatter: '{value}'
              }
            }, {
              type: 'value', // 使用value类型的x轴
              name: '充电次数', // 设置坐标轴名称为字符串
              position: 'top', // 将x轴放在底部
              nameRotate: -90, // 设置名称的旋转角度为90度，使其垂直显示
              nameGap: 25, // 设置名称与坐标轴之间的距离
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[1]
                }
              },
              alignTicks: true,
              axisLabel: {
                formatter: '{value}',
              }
            }
          ],
          yAxis: [
            {
              type: 'category', // 使用category类型的y轴
              data: this.orderListX
            }
          ],
          series: [
            {
              name: 'wifi链接数',
              type: 'bar',
              data: this.WifiListY,
              xAxisIndex: 0 // 使用第一个x轴
            },
            {
              name: '充电次数',
              type: 'bar',
              data: this.ChargeListY,
              xAxisIndex: 1 // 使用第一个x轴
            }
          ]
        };
      }
      this.option.title.textStyle.fontSize = fontSize;
      // this.getData(res);
    },
    async PleGetListWay() {
      let res = await PleGetList();
      console.log("大屏请求数据", res)
      this.sumMachineData = res.data.count_machine;
      this.sumOrderData = res.data.count_order;
      this.sumMoneyData = res.data.sum_money;
      this.baggedDate = res.data.list
      this.hoteDate = res.data.list_wifi;
      if (res.code == 1) {
        this.orderList = res.data.list_all;
        this.orderListX.splice(0)
        this.WifiListY.splice(0)
        this.ChargeListY.splice(0)
        this.orderList.forEach((item) => {
          item.time_point = item.time_point.split("-");
          item.time_point = item.time_point[1] + "-" + item.time_point[2];
          this.orderListX.push(item.time_point);
          this.ChargeListY.push(item.charge_num);
          this.WifiListY.push(item.wifi_num);
        });
        this.resizeECharts()
        // this.getData(res);
      }
    },



    getYMD() {
      let myDate = new Date();
      let myYear = myDate.getFullYear();
      let myMonth = myDate.getMonth() + 1;
      let myToday = myDate.getDate();
      myMonth = myMonth > 9 ? myMonth : "0" + myMonth;
      myToday = myToday > 9 ? myToday : "0" + myToday;
      let nowDate = myYear + "-" + myMonth + "-" + myToday;
      return nowDate;
    },
  },
};
</script>

<style lang="scss" scoped>
#screenView {
  padding: 0px 2rem;
  box-sizing: border-box;

  .top {
    position: relative;
    // height: px;

    h1 {
      font-size: 0.575rem;
      color: #00b7ee;
      text-align: center;
      line-height: 1.7rem;
    }

    .showTime {
      position: absolute;
      top: 0;
      right: 0.375rem;
      line-height: 0.9375rem;
      font-size: 0.35rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .content {
    min-width: 1024px;
    max-width: 1920px;
    // padding: 0.125rem 0.125rem 0;
    margin-top: 0.3rem;
    display: flex;
    height: 7.2rem;
    .column {
      width: 545px;

      &:nth-child(2) {
        width: 641px;
        margin: 0 47px;
        overflow: hidden;
      }
    }


  }

  .column2 {
    min-width: 1024px;
    max-width: 1920px;

  }

  /* 手机屏幕适配 */
  @media screen and (max-width: 768px) {
    html {
      font-size: 20px !important;


    }


    .column,
    .column2 {
      width: 20rem !important;

      // &:nth-child(2) {
      //   width: 641px;
      //   margin: 0 47px;
      //   overflow: hidden;
      // }
    }

    .columu_map {
      width: 20rem !important;
      height: 3rem;
      margin: 1rem 0 !important;
    }

    .content {
      min-width: 360px !important;
      max-width: 768px !important;
      // padding: 0.125rem 0.125rem 0;
      margin-top: 37px;
      display: block;




    }

    .showTime {
      position: absolute;
      top: 1.8rem !important;
      right: 0;
      border: 1px solid #000;
      // margin-top: 0.5rem;
      // right: 0.375rem;
      line-height: 0.9375rem;
      font-size: 0.6rem !important;
      color: rgba(255, 255, 255, 0.7);
    }

    .top {
      // border: 1px solid rgb(196, 13, 13);
      position: relative;
      height: 2rem;

    }

    h1 {
      font-size: 1rem !important;
      color: #00b7ee;
      text-align: center;
      line-height: 2.5rem !important;
    }

    #screenView {
      padding: 0px 1rem;
      box-sizing: border-box;





      .column2 {
        width: 100%;

      }
    }
  }


  /* 平板屏幕适配 */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    html {
      font-size: 20px !important;
    }
  }

  @media screen and (max-width: 1024px) {
    html {
      font-size: 42px !important;
    }
  }

  @media screen and (min-width: 1920px) {
    html {
      font-size: 80px !important;
    }
  }
}
</style>