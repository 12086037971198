<template>
    <div class="my-video">
        <!-- <div class="play" @click="playVideo()"> -->
            <!-- {{ buttonLabel }} -->
            <!-- <img v-if="!isPlaying" src="../assets/images/sart.png" alt="播放">
            <img v-else src="../assets/images/stop.png" alt="暂停"> -->
        <!-- </div> -->
        <video ref="myVideo" muted  autoplay loop style="width: 100%;" controls>
            <source src="../assets/images/plm.mp4" type="video/mp4">
            您的浏览器不支持 video 标签。
        </video>
    </div>
</template>
  
<script>
// import MySwipper from "./MySwipper.vue";

export default {
    props: {
        src: {
            // 地图数据
            type: String,
            default: '',
        },

    },
    components: {

    },

    data() {
        return {
            listData: {
                end_time: "",
                lenth: 10,
                start_time: "",
            },
            isPlaying: false
            // mapData: [],
        };
    },
    computed: {
        buttonLabel() {
            return this.isPlaying ? '点击暂停' : '点击播放';
        },
    },

    // 组件加载就初始化
    methods: {
        playVideo() {
            const video = this.$refs.myVideo;

            if (this.isPlaying) {
                video.muted  = true; // 开启声音
            } else {
                video.muted  = false;
            }
            this.isPlaying = !this.isPlaying;
        }
    }
};
</script>
  
<style lang="scss" scoped>
.my-video {
    position: relative;
    //   height: 3rem; /* 根据需要调整容器的高度 */
    border: 1px solid #0095d1;
    padding: 0;
    margin-bottom: 25px;
    //   height: 100%;
    height: auto;

    /* 或者设置为视频的高度 */
    &:hover .play {
        opacity: 1;
    }

}

video {
    vertical-align: middle;
    margin-bottom: 0;
}

.play {
    opacity: 0;
    position: absolute;
    z-index: 66;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);


}
</style>
  