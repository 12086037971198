<template>
  <div class="MyCharts">

    <div class="panel" :class="type">
      <div class="first_span"></div>
      <div class="list_span"></div>
      <div class="chart" id="chart-container"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    option: {
      // 图表数据
      type: Object,
      default: () => ({}),
    },
    type: {
      // 图表类型
      type: String,
      default: "bar",
    },
    title: {
      // 标题
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  watch: {
    // 监听异步数据变化了就重新初始化
    option: {
      handler() {
        if (this.myChart) {
        this.myChart.dispose(); // 正确调用销毁方法
      }
        this.initEchart();
      },
      deep: true, // 深度监听
    },
  },
  methods: {
    // 初始化echarts
    initEchart() {
      this.myChart = echarts.init(
        document.querySelector(`.${this.type} .chart`)
      );
      this.myChart.setOption(this.option);
      // 监听浏览器尺寸变化后重新初始化
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
  // 组件加载就初始化
  mounted() {
    this.initEchart();
  },
};
</script>

<style lang="scss" scoped>
.MyCharts {
  width: 100%;

  .panel {
    width: 100%;
    position: relative;
    height: 4rem;
    border: 1px solid rgb(0, 137, 255);
    background: rgba(#0089ff, 13%);
    box-sizing: border-box;
    padding: 0.1rem 0.3rem;

    .first_span {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
      border-top: 2px solid rgb(0, 137, 255);
      border-left: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    &::before {
      position: absolute;
      bottom: 0px;
      left: 0px;
      content: "";
      width: 18px;
      height: 18px;
      border-bottom: 2px solid rgb(0, 137, 255);
      border-left: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    .list_span {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 20px;
      height: 20px;
      border-bottom: 2px solid rgb(0, 137, 255);
      border-right: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      content: "";
      width: 18px;
      height: 18px;
      border-top: 2px solid rgb(0, 137, 255);
      border-right: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    .chart {
      width: 100%;
      height: 100%;
    }
  }
}

/* 手机屏幕适配 */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px !important;


  }/* 在样式表中定义富文本样式规则 */

  .panel {
    width: 100%;
    position: relative;
    height: 40rem !important;
    border: 1px solid rgb(0, 137, 255);
    background: rgba(#0089ff, 13%);
    box-sizing: border-box;
    padding: 0.1rem 0.3rem;

    .first_span {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 20px;
      height: 20px;
      border-top: 2px solid rgb(0, 137, 255);
      border-left: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    &::before {
      position: absolute;
      bottom: 0px;
      left: 0px;
      content: "";
      width: 18px;
      height: 18px;
      border-bottom: 2px solid rgb(0, 137, 255);
      border-left: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    .list_span {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 20px;
      height: 20px;
      border-bottom: 2px solid rgb(0, 137, 255);
      border-right: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      content: "";
      width: 18px;
      height: 18px;
      border-top: 2px solid rgb(0, 137, 255);
      border-right: 2px solid rgb(0, 137, 255);
      z-index: 1;
    }

    .chart {
      width: 100%;
      height: 100%;
    }
  }

}
</style>
