<template>
  <div class='bos' :class="title=='充电实时订单'?'box':'boxs'">
    <h2>
      {{ title }}
      <slot name="control"></slot>
    </h2>
    <div class="box_max" v-if="title=='充电实时订单'">
      <div class="box-swipper" :style="{ top: tableTop + 'px' }">
        <div
          class="swipper-item"
          v-for="(item, index) in tableList"
          :key="index"
        >
        {{ item.create_time }} {{ item.hotelName }}-{{ item.buyer_name ? item.buyer_name : "保密" }}-{{
            item.pay_code ? "微信支付" : "支付宝支付"
          }}-{{ item.amount }}
        </div>
      </div>
    </div>
    <div class="box_max" v-if="title=='wifi实时链接'">
      <div class="box-swipper" :style="{ top: tableTop + 'px' }">
        <div
          class="swipper-item"
          v-for="(item, index) in tableList"
          :key="index"
        >
        {{ item.create_time }} {{ item.hotelName }}-{{ item.buyer_name ? item.buyer_name : "保密" }}-{{
            item.source_from=='wx' ? "微信" : "支付宝"
          }}
        </div>
      </div>
    </div>
    <div class="first_span"></div>
    <div class="list_span"></div>
  </div>
</template>

<script>
export default {
  props: ["title", "tableDate"],
  data() {
    return {
      tableTimer: null,
      tableTop: 0,
      tableList: [],
      tableListSize: 0,
      componentTimer: null,
      visibleSize: 4, //容器内可视最大完整行数
      lineHeight: 42, //每行的实际高度（包含margin-top/bottom,border等）
      componentTimerInterval: 3600000, //刷新数据的时间间隔
      tableTimerInterval: 42, //向上滚动 1px 所需要的时间，越小越快，推荐值 100
    };
  },

  watch: {
    tableDate: {
      handler: function () {
        clearInterval(this.componentTimer);
        this.bsGetProductProcess();
        this.componentTimerFun();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.componentTimer);
    clearInterval(this.tableTimer);
  },
  methods: {
    //获取列表数据
    bsGetProductProcess() {
      clearInterval(this.tableTimer);
      this.tableTop = 0;
      if (this.tableDate != "") {
        this.tableList = this.tableDate;
        this.tableActionFun();
      }
    },
    tableActionFun() {
      this.tableListSize = this.tableList.length;
      if (this.tableListSize > this.visibleSize) {
        this.tableList = this.tableList.concat(this.tableList);
        this.tableTimerFun();
      }
    },
    tableTimerFun() {
      var count = 0;
      this.tableTimer = setInterval(() => {
        if (count < (this.tableList.length / 2) * this.lineHeight) {
          this.tableTop -= 1;
          count++;
        } else {
          count = 0;
          this.tableTop = 0;
        }
      }, this.tableTimerInterval);
    },
    componentTimerFun() {
      this.componentTimer = setInterval(() => {
        this.bsGetProductProcess();
      }, this.componentTimerInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
.bos{

  .first_span {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
    z-index: 1;
  }
  &::before {
    position: absolute;
    bottom: -1px;
    left: -1px;
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
        z-index: 1;
  }
  .list_span {
    position: absolute;
    bottom: -1px;
    right:-1px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
        z-index: 1;
  }
  &::after {
    position: absolute;
    top: -1px;
    right: -1px;
    content: "";
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
        z-index: 1;
  }
  h2 {
    height: 0.4rem;
    line-height: 0.4rem;
    color: #fff;
    font-size: 0.25rem;
    font-weight: 400;
  }
}
.box {
  position: relative;
  height: 3.0rem;
  border: 1px solid #0095d1;
  padding: 0.1875rem 0.3125rem;
  margin-bottom:25px;
  .box_max {
    height: 2.2rem;
    overflow: hidden;
    position: relative;
    .box-swipper {
      width: 100%;
      position: absolute;
      left: 0;
      .swipper-item {
        height: 35px;
        line-height: 35px;
        color: #eee;
        font-size: 0.2rem;
        background: rgba(3, 145, 167, 0.1);
        border: 1px solid rgb(4, 114, 131);
        padding-left: 0.25rem;
        margin-top: 7px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
      }
    }
  }
 
}
.boxs {
  position: relative;
  height: 6.8rem;
  border: 1px solid #0095d1;
  padding: 0.1875rem 0.3125rem;
  margin-bottom:25px;
 
  .box_max {
    height: 6.0rem;
    overflow: hidden;
    position: relative;
    .box-swipper {
      width: 100%;
      position: absolute;
      left: 0;
      .swipper-item {
        height: 35px;
        line-height: 35px;
        color: #eee;
        font-size: 0.2rem;
        background: rgba(3, 145, 167, 0.1);
        border: 1px solid rgb(4, 114, 131);
        padding-left: 0.25rem;
        margin-top: 7px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
      }
    }
  }

}
 /* 手机屏幕适配 */
 @media screen and (max-width: 768px) {
    html {
      font-size: 20px !important;


    }
    h2 {
    height: 0.7rem !important;
    line-height: 0.7rem !important;

    font-size: 0.7rem !important;
    
  }
    .box_max{
     height: 7rem !important; 
    }
    .bos{
      height: 8rem !important;
    }
    .swipper-item {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
        color: #eee;
        font-size: 0.6rem !important;
        background: rgba(3, 145, 167, 0.1);
        border: 1px solid rgb(4, 114, 131);
        padding-left: 0.25rem;
        margin-top: 7px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
      }
     

  }
</style>